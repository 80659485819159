import { AUTH_TOKEN } from 'redux/constants/Auth';
import { message as antdMessage } from "antd";
import utils from 'utils';

export default async (url, data, method = 'GET') => {
  const headers = new Headers();
  headers.append('Content-Type', 'application/json');
  headers.append('Authorization', localStorage.getItem(AUTH_TOKEN));
  headers.append('Referrer-Policy', 'no-referrer');

  const options = {
    headers: headers,
    method: method,
    body: JSON.stringify(data)
  };

  try {
  const response = await fetch(url, options);

  if (!response.ok) {
    // Handle different status codes
    if (response.status === 401) {
      // Handle 401 status
    } else if (response.status === 429) {
      // Handle 429 status
    } else if (response.status === 403) {
      // Handle 403 status
    } else if (response.status === 502) {
      // Handle 502 status
    } else {
      throw new Error('Something went wrong');
    }
  }

  return response.json();
} catch (error) {
 
 
  if (error.name === 'TypeError' && error.message === 'Failed to fetch') {
    // Handle fetch failure gracefully
    localStorage.removeItem(AUTH_TOKEN);
    window.location.href = '/auth/login?err=sessionexpiry';
  }

  console.log(error);
  throw error;
}

};
