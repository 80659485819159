export const API_BASE_URL = 'https://your-api-url.com/'

export const NOTES_TYPE = {
  //3 is daily notes
  //5 is coach notes
  // 4 lesson learnt
  // 2 is trade notes, accepts trade-id
  //1 is other notes

	TRADE_NOTES : '2',
	DAILY_NOTES : '3',
	COACH_NOTES : '5',
	LESSON_LEARNT : '4',
	COACH_TRADE_NOTES :'6'
}