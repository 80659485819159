import React from 'react'
import { connect } from 'react-redux'
import { Radio, Switch, Button, message } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {
	toggleCollapsedNav,
	onNavTypeChange,
	onNavStyleChange,
	onTopNavColorChange,
	onHeaderNavColorChange,
	onSwitchTheme,
	onDirectionChange
} from 'redux/actions/Theme';
import NavLanguage from './NavLanguage';
import {
	SIDE_NAV_LIGHT,
	NAV_TYPE_SIDE,
	NAV_TYPE_TOP,
	SIDE_NAV_DARK,
	DIR_RTL,
	DIR_LTR
} from 'constants/ThemeConstant';
import { useThemeSwitcher } from "react-css-theme-switcher";
import utils from 'utils/index';


const ListOption = ({ name, selector, disabled, vertical }) => (
	<div className={`my-4 ${vertical ? '' : 'd-flex align-items-center justify-content-between'}`}>
		<div className={`${disabled ? 'opacity-0-3' : ''} ${vertical ? 'mb-3' : ''}`}>{name}</div>
		<div>{selector}</div>
	</div>
)

export const ThemeSlider = ({
	navType,
	sideNavTheme,
	navCollapsed,
	topNavColor,
	headerNavColor,
	locale,
	currentTheme,
	toggleCollapsedNav,
	onNavTypeChange,
	onNavStyleChange,
	onTopNavColorChange,
	onHeaderNavColorChange,
	onSwitchTheme,
	direction,
	onDirectionChange
}) => {
	const isNavTop = navType === NAV_TYPE_TOP ? true : false
	const isCollapse = navCollapsed

	const { switcher, themes } = useThemeSwitcher();

	const toggleTheme = (isChecked) => {
		onHeaderNavColorChange('')
		const changedTheme = isChecked ? 'dark' : 'light'
		onSwitchTheme(changedTheme)
		switcher({ theme: themes[changedTheme] });
	};

	//Sun
	const DarkSvg = () => (
		<svg xmlns="http://www.w3.org/2000/svg" width="1.3em" height="1.3em" viewBox="0 -5 26 26" fill="#ffffff" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="bevel">
			<circle cx="12" cy="12" r="5" />
			<path d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4" />
		</svg>
	);

	//Moon
	const LightSvg = () => (
		<svg xmlns="http://www.w3.org/2000/svg" width="1.2em" height="1.2em" viewBox="0 -2 24 24" fill="none" stroke="#000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="bevel">

			<path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z" />
		</svg>
	);

	<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="arcs"><path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path></svg>

	const DarkIcon = props => <Icon component={DarkSvg} {...props} />;
	const LightIcon = props => <Icon component={LightSvg} {...props} />;

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>

				<div className="align-items-center">
					<Switch checked={currentTheme === 'dark'} onChange={toggleTheme} checkedChildren={<DarkIcon style={{ color: 'white' }} />}
						unCheckedChildren={<LightIcon style={{ color: 'white' }} />} />
				</div>
			</div>

		</>
	)
}

const mapStateToProps = ({ theme }) => {
	const { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction } = theme;
	return { navType, sideNavTheme, navCollapsed, topNavColor, headerNavColor, locale, currentTheme, direction }
};

const mapDispatchToProps = {
	toggleCollapsedNav,
	onNavTypeChange,
	onNavStyleChange,
	onTopNavColorChange,
	onHeaderNavColorChange,
	onSwitchTheme,
	onDirectionChange
}

export default connect(mapStateToProps, mapDispatchToProps)(ThemeSlider)
