import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button, DatePicker, Row, Col, Space, Form, Alert, Skeleton} from 'antd';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
import PropTypes from 'prop-types';
import moment from 'moment';
import NotesService from 'services/NotesService'
import debounce from 'lodash/debounce';
import utils from 'utils';
import { NOTES_TYPE} from 'constants/ApiConstant';


const RichTextEditor = ({ type }) => {


  const [coachNotesText, setCoachNotesText] = useState('');
  const [dailyNotesText, setDailyNotesText] = useState('');
  
  const dateFormat = 'MM/DD/YYYY';
  const [selectedDate, setSelectedDate] = useState(dayjs().format(dateFormat)); //today
  const [dailyNotesDateSelected, setDailyNotesDateSelected] = useState(dayjs().format(dateFormat)); //today

  const editorRef = useRef(null);
  const datePickerRef = useRef(null);

  const [isNoteLoading, setIsNoteLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);


  //clone.weekday is not a function, workaround as documented here.
  //https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
  dayjs.extend(weekday)
  dayjs.extend(localeData)


  //3 is daily notes
  //5 is coach notes
  // 4 lesson learnt
  // 2 is trade notes, accepts trade-id
  //1 is other notes

  const [form] = Form.useForm();

  const handleEditorChange = (newValue) => {

      if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.DAILY_NOTES)
      {
        setDailyNotesText(newValue);
      }

      if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.COACH_NOTES)
      {
          setCoachNotesText(newValue);
      }
    setIsSaving(true);
  };

  const handleDPDateChange = (date) => {

    if(date != null)
    {
      if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.DAILY_NOTES)
        {
            setDailyNotesDateSelected(date);
            RefreshEditor(date);
        }

    if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.COACH_NOTES)
        {
            setSelectedDate(date);
            RefreshEditor(date);
        }
    }
    

  };

  const getDailyNotesForDate = (notesDate) => {
     //Get Daily notes
      NotesService
      .getNotes(notesDate,NOTES_TYPE.DAILY_NOTES,null)
      .then((res) => {
        setIsNoteLoading(false);
        if (res.length >0)
        {
              setDailyNotesText(res[0].notes);  
        } else 
        {
          setDailyNotesText('');
        }
      });
  }

  const getCoachNotesForDate =(notesDate) => {
     //Get Coach Notes

      NotesService
      .getNotes(notesDate,NOTES_TYPE.COACH_NOTES,null)
      .then((res) => {
        setIsNoteLoading(false);
        if (res.length >0)
        {
                setCoachNotesText(res.pop().notes);
        } else 
        {
          setCoachNotesText('');
        }
      });
  }

  // Debounce the save operation to avoid triggering it too frequently
  const debouncedSave = useRef(
    debounce((NotesDateSelected, notesValue) => {
      // Save the notes to the server here, when it not empty
      if(notesValue != '<p><br></p>' && notesValue != "") 
      {
              const formattedDate = dayjs(NotesDateSelected).format(dateFormat);
              NotesService.saveNotes(formattedDate,localStorage.getItem('selectedNotesType'),notesValue,null); 
              //tradeId is null above
      }
      setIsSaving(false);
    }, 1000)
  ).current;

  // This will be called only the first time the component loads.
  useEffect(() => {
    getDailyNotesForDate(selectedDate);
    getCoachNotesForDate(selectedDate);
  }, []);

   useEffect(() => {
    if (isSaving) {
      if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.DAILY_NOTES)
      {
              debouncedSave(dailyNotesDateSelected, dailyNotesText);
      }

       if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.COACH_NOTES)
      {
             debouncedSave(selectedDate,coachNotesText);
      }
    }
  }, [dailyNotesText, coachNotesText, isSaving, debouncedSave]);


  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
    ]
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const editorStyle = {
    height: '25em',
    paddingBottom: '2px',
    width: '100em', // Makes the editor take the full width of its parent container
    minWidth: '300px' // Ensures the editor doesn't get too narrow
  };


  function RefreshEditor(givenDate){

       if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.DAILY_NOTES)
        {
              setDailyNotesDateSelected(givenDate);
              const formattedNewDate = dayjs(givenDate).format(dateFormat);
              getDailyNotesForDate(formattedNewDate);
             if (editorRef.current) {
                  editorRef.current.getEditor().setContents(dailyNotesText);
              }

        }

        if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.COACH_NOTES)
        {
              setSelectedDate(givenDate);
              const formattedNewDate = dayjs(givenDate).format(dateFormat);
              getCoachNotesForDate(formattedNewDate);
             if (editorRef.current) {
                  editorRef.current.getEditor().setContents(coachNotesText);
              }

        }

  }


  function handleClicks(clickType)
  {

     var newDate;
    
     var currentValue = form.getFieldValue('datePickerField');

     if (currentValue == null)
     {
      currentValue = dayjs().format(dateFormat);
     }

     if(currentValue != null)
     {

      if (clickType === 'right')
        {
          newDate = dayjs(currentValue, dateFormat).add(1, 'day');
     
        }

      if (clickType === 'left')
      {
        newDate = dayjs(currentValue, dateFormat).subtract(1, 'day');

      }
          form.setFieldsValue({ 'datePickerField': newDate });
          RefreshEditor(newDate);
     }
   
  }

  function handleLeftClick() {
      handleClicks('left');
  }

  function handleRightClick() {

    handleClicks('right');
  }

  //returns what to show to the editor
  function whatToDisplay()
  {
    if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.DAILY_NOTES)
    {
      return dailyNotesText;
    }

  if(localStorage.getItem('selectedNotesType') === NOTES_TYPE.COACH_NOTES)
    {
      return coachNotesText;
    }

  }



  return (

    <>

    <Row justify="space-around" align="center" >

      <Col>
        <CaretLeftOutlined 
          onClick={handleLeftClick}
        />
      </Col>

      <Col span={12} offset={6}>

           <Form form={form}>
             <Form.Item name="datePickerField">

               <DatePicker 
                    defaultValue={dayjs()} 
                    format={dateFormat} 
                    showToday={true}
                    onChange={handleDPDateChange}
                    ref={datePickerRef}

              />


            </Form.Item>
         </Form>

      </Col>
       <Col>
        <CaretRightOutlined 
          onClick={handleRightClick}
        />
      </Col>



    </Row>

<Row>
  <a href="/reports/notes/notes">View All Notes</a>
</Row>
<br />

<Row>

{
                  isNoteLoading ? (
                    <div>
                      <Skeleton active />
                    </div>
                  ) : (

                  
                   <ReactQuill
                      value={whatToDisplay()}
                      onChange={handleEditorChange}
                      modules={modules}
                      formats={formats}
                      style={editorStyle}
                      ref={editorRef}
                    />


                   

                      


                  ) 
                }


    

</Row>
<p></p>

    </>
  );
};

RichTextEditor.propTypes = {
  type: PropTypes.string,
};

RichTextEditor.defaultProps = {
  type: 'daily',
};

export default RichTextEditor;
