import React, { useState } from 'react';
import { Input, Button, Card, Row, Col, Skeleton } from 'antd';
import openai from 'openai';

function TradelyticsGPT() {

  const { TextArea } = Input;

  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);


  async function handlePromptSubmit(event) {
    event.preventDefault();

     setIsLoading(true);

    const { Configuration, OpenAIApi } = require("openai");

    const configuration = new Configuration({
      apiKey: process.env.REACT_APP_CHATGPT_API,
    });
    const openai = new OpenAIApi(configuration);

     const completion = await openai.createCompletion({
      model: "text-davinci-003",
      prompt: prompt,
      max_tokens:3800,

    });

    setResponse(completion.data.choices[0].text);
    setIsLoading(false);
  }

  function handlePromptChange(event) {
    setPrompt(event.target.value);
  }

  return (
        <>
        <div>
                <TextArea rows={4} value={prompt} onChange={handlePromptChange} />

               <Button type="default" onClick={handlePromptSubmit}>Ask</Button>


              <Card>
                      {
                  isLoading ? (
                    <div>
                      <Skeleton active />
                    </div>
                  ) : (

                    response && 
                    <p>{response}</p>
                  ) 
                }
              </Card>
      </div>
        </>
  );
}

export default TradelyticsGPT;
