import React from "react";
import { Menu, Dropdown, Avatar, Divider } from "antd";
import { connect } from 'react-redux'
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  UserOutlined
} from '@ant-design/icons';
import Icon from 'components/util-components/Icon';
import { signOut } from 'redux/actions/Auth';
import ThemeConfigurator from 'components/layout-components/ThemeSlider'

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    path: "/settings"
  },

  {
    title: "Account Settings",
    icon: SettingOutlined,
    path: "/settings"
  },
  {
    title: "Help",
    icon: QuestionCircleOutlined,
    path: "https://www.tradelytics.ai"
  }
]



export const NavProfile = ({ signOut }) => {
  var profileImg = localStorage.getItem('loggedin_user_photo_url');
  const logged_in_display_user = localStorage.getItem('loggedin_user_name');
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <div className="pl-3">
            <h4 className="mb-0">{logged_in_display_user}</h4>

          </div>
        </div>
      </div>

      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>

              </Menu.Item>

            );
          })}

          <Menu.Item key={menuItem.legth + 1} onClick={e => signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <>
      <div className="mb-0 mr-3 font-weight-semibold">
        {localStorage.getItem('loggedin_user_email')}
      </div>
      <div style={{ color: 'gray' }}>
        [{process.env.NODE_ENV}]
      </div>
      <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item>
            <Icon type={UserOutlined} />
          </Menu.Item>
        </Menu>
      </Dropdown>
    </>
  );
}

export default connect(null, { signOut })(NavProfile)
