const FirebaseConfig = {
  apiKey: "AIzaSyB4kfDDNHkBjlTqtwRPaqMaplrxXHdKl3s",
  authDomain: "tradelytics-auth.firebaseapp.com",
  projectId: "tradelytics-auth",
  storageBucket: "tradelytics-auth.appspot.com",
  messagingSenderId: "459545341368",
  appId: "1:459545341368:web:b7833c8490b9faea54f34d",
  measurementId: "G-5KV2EKV8FM"
};

export default FirebaseConfig

