import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Tabs, Row, Col, Form, Input, Button, DatePicker } from 'antd';
import RichTextEditor from 'components/shared-components/Notes/RichTextEditor';
import {NOTES_TYPE} from 'constants/ApiConstant';


const { TabPane } = Tabs;

const DailyAndCoachNotes = ({ type }) => {
  
  const tabRef1 = useRef(null);
  const tabRef2 = useRef(null);

  const [activeKey, setActiveKey] = useState('tab1');
  let activeTabRef = tabRef1;

   const handleTabClick = (key) => {
      if (key === 'tab1') {
        setActiveKey('tab1');
        localStorage.setItem('selectedNotesType',NOTES_TYPE.DAILY_NOTES);
      } else if (key === 'tab2') {
        setActiveKey('tab2');
        localStorage.setItem('selectedNotesType',NOTES_TYPE.COACH_NOTES);
      }
    };

  useEffect(() => {
   
    if (type === 'coach') {
      handleTabClick('tab2');
    }
  }, [type]);

  if (type === 'daily') {
    activeTabRef = tabRef1;
  }

  return (
    <>
      <div className="container">
        <Tabs activeKey={activeKey} onTabClick={handleTabClick}>
          <TabPane tab={<div ref={tabRef1}>Daily Journal</div>} key="tab1">
            <Row>
              <Col>
                    <RichTextEditor
                    	type="daily"
                    />               
              </Col>
            </Row>
          </TabPane>
          <TabPane tab={<div ref={tabRef2}>Coach Notes</div>} key="tab2">
            <Row>
              <Col>
                      <RichTextEditor 
                      	type="coach"
                      />    
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
      <p></p>

    </>

  );
};

DailyAndCoachNotes.propTypes = {
  type: PropTypes.string,
};

DailyAndCoachNotes.defaultProps = {
  type: 'daily',
};

export default DailyAndCoachNotes;
