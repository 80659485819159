import {
  DashboardOutlined,
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined,
  SettingOutlined,
  BarChartOutlined,
  UnorderedListOutlined,
  AimOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'


const dashBoardNavTree = [{

  key: 'dashboards-dashboard',
  path: `${APP_PREFIX_PATH}/dashboard`,
  title: 'sidenav.dashboard.dashboard',
  icon: DashboardOutlined,
  breadcrumb: true,
  filter: true,
  addTrade: true,
  submenu: [
    {
      key: 'dashboards-dashboard',
      path: `${APP_PREFIX_PATH}/dashboard`,
      title: 'sidenav.dashboard.dashboard',
      icon: DashboardOutlined,
      breadcrumb: false,
      filter: true,
      addTrade: true,
      submenu: []
    },

    {
      key: 'dashboards-plan',
      path: `${APP_PREFIX_PATH}/plan`,
      title: 'sidenav.dashboard.plan',
      icon: AimOutlined,
      breadcrumb: false,
      filter: true,
      addTrade: true,
      submenu: []
    },

    {
      key: 'dashboards-trades',
      path: `${APP_PREFIX_PATH}/trades`,
      title: 'sidenav.dashboard.trades',
      icon: UnorderedListOutlined,
      breadcrumb: true,
      filter: true,
      addTrade: true,
      submenu: [
        {
          key: 'dashboards-trades-alltrades',
          path: `${APP_PREFIX_PATH}/trades/`,
          title: 'sidenav.dashboard.trades.alltrades',
          icon: '',
          breadcrumb: true,
          filter: true,
          addTrade: true,
          submenu: []
        },
        {
          key: 'dashboards-trades-addtrade',
          path: `${APP_PREFIX_PATH}/trades/addtrade`,
          title: 'sidenav.dashboard.trades.addtrade',
          icon: '',
          breadcrumb: true,
          filter: true,
          addTrade: true,
          submenu: []
        },

        {
          key: 'dashboards-trades-importhistory',
          path: `${APP_PREFIX_PATH}/trades/importhistory`,
          title: 'sidenav.dashboard.trades.importhistory',
          icon: '',
          breadcrumb: true,
          filter: true,
          addTrade: true,
          submenu: []
        },

      ]
    },
    {
      key: 'dashboards-reports',
      path: `${APP_PREFIX_PATH}/reports`,
      title: 'sidenav.dashboard.reports',
      icon: BarChartOutlined,
      breadcrumb: true,
      filter: true,
      addTrade: true,
      submenu: [
        {
          key: 'dashboards-reports-pnl',
          path: `${APP_PREFIX_PATH}/reports/pnl`,
          title: 'sidenav.dashboard.reports.pnl',
          icon: '',
          breadcrumb: true,
          filter: true,
          addTrade: true,
          submenu: []
        },
        {
          key: 'dashboards-reports-calendar',
          path: `${APP_PREFIX_PATH}/reports/calendar`,
          title: 'sidenav.dashboard.reports.calendar',
          icon: '',
          breadcrumb: true,
          filter: true,
          addTrade: true,
          submenu: []
        },
        {
          key: 'dashboards-reports-asset',
          path: `${APP_PREFIX_PATH}/reports/asset`,
          title: 'sidenav.dashboard.reports.asset',
          icon: '',
          breadcrumb: false,
          filter: true,
          addTrade: true,
          submenu: []
        },

        {
          key: 'dashboards-reports-style',
          path: `${APP_PREFIX_PATH}/reports/style`,
          title: 'sidenav.dashboard.reports.style',
          icon: '',
          breadcrumb: false,
          filter: true,
          addTrade: true,
          submenu: []
        },
        {
          key: 'dashboards-reports-sentiment',
          path: `${APP_PREFIX_PATH}/reports/sentiment`,
          title: 'sidenav.dashboard.reports.sentiment',
          icon: '',
          breadcrumb: false,
          filter: true,
          addTrade: true,
          submenu: []
        },
        {
          key: 'dashboards-reports-rules',
          path: `${APP_PREFIX_PATH}/reports/rules`,
          title: 'sidenav.dashboard.reports.rules',
          icon: '',
          breadcrumb: false,
          filter: true,
          addTrade: true,
          submenu: []
        },
        {
          key: 'dashboards-reports-notes',
          path: `${APP_PREFIX_PATH}/reports/notes`,
          title: 'sidenav.dashboard.reports.notes',
          icon: '',
          breadcrumb: false,
          filter: true,
          addTrade: true,
          submenu: []
        },
        {
          key: 'dashboards-reports-portfolio',
          path: `${APP_PREFIX_PATH}/reports/portfolio`,
          title: 'sidenav.dashboard.reports.portfolio',
          icon: '',
          breadcrumb: false,
          filter: true,
          addTrade: true,
          submenu: []
        }
        ,
        {
          key: 'dashboards-reports-tags',
          path: `${APP_PREFIX_PATH}/reports/tags`,
          title: 'sidenav.dashboard.reports.tags',
          icon: '',
          breadcrumb: false,
          filter: true,
          addTrade: true,
          submenu: []
        }
      ]
    },
    {
      key: 'dashboards-settings',
      path: `${APP_PREFIX_PATH}/settings`,
      title: 'sidenav.dashboard.settings',
      icon: SettingOutlined,
      breadcrumb: true,
      filter: true,
      addTrade: true,
      submenu: []
    }
  ]
}]


const navigationConfig = [
  ...dashBoardNavTree
]

export default navigationConfig;