import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>

        <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboards/dashboard`))} />
        <Route path={`${APP_PREFIX_PATH}/apps`} component={lazy(() => import(`./apps`))} />
        <Route path={`${APP_PREFIX_PATH}/components`} component={lazy(() => import(`./components`))} />
        <Route path={`${APP_PREFIX_PATH}/pages`} component={lazy(() => import(`./pages`))} />
        <Route path={`${APP_PREFIX_PATH}/maps`} component={lazy(() => import(`./maps`))} />
        <Route path={`${APP_PREFIX_PATH}/charts`} component={lazy(() => import(`./charts`))} />
        <Route path={`${APP_PREFIX_PATH}/docs`} component={lazy(() => import(`./docs`))} />
        <Route path={`${APP_PREFIX_PATH}/plan`} component={lazy(() => import(`./plan`))} />
        <Route path={`${APP_PREFIX_PATH}/reports`} component={lazy(() => import(`./reports`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/trades/trade-detail/:id`} component={lazy(() => import(`./trade-detail`))} />
        <Route path={`${APP_PREFIX_PATH}/trades`} component={lazy(() => import(`./trades`))} />

      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);
