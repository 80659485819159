import fetch from 'auth/FetchInterceptor';
import instrumentedFetch from './InstrumentedFetch';
import utils from 'utils';
import { NOTES_TYPE} from 'constants/ApiConstant';
import { API_BASE_URL } from 'configs/AppConfig'




const NotesService = {
  getNotes: function (noteDate,noteTypeId, tradeId, id) {

    var url;

    if(id != null)
    {
        url = API_BASE_URL + 'user/notes' + '/?id=' + id;

    } else
    {
       if(noteTypeId === NOTES_TYPE.TRADE_NOTES)
          {
            url = API_BASE_URL + 'user/notes' + '/?typeId=' + noteTypeId + '&tradeId=' + tradeId;
          } else
          {
            url = API_BASE_URL + 'user/notes' + '/?typeId=' + noteTypeId + '&date=' + noteDate;
          }
    }

   
    
    return new Promise((resolve, reject) => {
        resolve(instrumentedFetch(url));
    }).then(res => res);
  },

  getNotesReport: function () {

    const url=API_BASE_URL + 'user/notes?a=b';
    
    return new Promise((resolve, reject) => {
        resolve(instrumentedFetch(url));
    }).then(res => res);
  },

  getNotesById: function (noteId) {

    
    const url= API_BASE_URL + 'user/note/'+noteId;
    //const url= 'http://localhost:8080/' + 'user/note/'+noteId;
    
    return new Promise((resolve, reject) => {
        resolve(instrumentedFetch(url));
    }).then(res => res);
  },

  saveFavorite: function(noteId, isFavorite)
  {

      var noteBody;

      if(noteId !=null)
        {
          noteBody = {
              "id": noteId,
              "is_favorited": isFavorite
            };
        }

      return new Promise((resolve, reject) => {
        resolve(instrumentedFetch(
            API_BASE_URL + 'user/notes',
            noteBody,
            'PATCH'
            ));
    }).then(res => res)
     .then(data => {
    })
    .catch(error => {
      console.error('Error saving favorite:', error);
    });

  },

  saveNotes: function (noteDate, noteTypeId,notesContent, tradeId) {

    var noteBody;

    
     if(noteTypeId === NOTES_TYPE.TRADE_NOTES)
      {
        noteBody = {
            "note_type_id": noteTypeId,
            "notes": notesContent,
            "reference_id": tradeId
          };
        } else
        {
          noteBody = {
            "note_type_id": noteTypeId,
            "notes": notesContent,
            "note_date": noteDate

          };
        }
    
   

    return new Promise((resolve, reject) => {
        resolve(instrumentedFetch(
            API_BASE_URL + 'user/notes',
            noteBody,
            'POST'
            ));
    }).then(res => res)
     .then(data => {
    })
    .catch(error => {
      console.error('Error saving notes:', error);
    });

  },

};


export default NotesService