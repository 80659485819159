import fetch from 'auth/FetchInterceptor';
import instrumentedFetch from './InstrumentedFetch';
import utils from 'utils';
import { API_BASE_URL } from 'configs/AppConfig'



const UserService = {
  getUserAccount: function (params) {
    return new Promise((resolve, reject) => {
        resolve(instrumentedFetch(API_BASE_URL+'user/broker-account'));
    }).then(res => res);
  }
};


export default UserService