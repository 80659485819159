import dayjs from 'dayjs';
import moment from 'moment';
import UserService from './../services/UserService';



class Utils {
	navCollapsed = false;
	static getNavCollapsed() {
		return this.navCollapsed;
	}
	static setNavCollapsed(navCollapsed){
		this.navCollapsed = navCollapsed;
	}
	/**
	 * Get first character from first & last sentences of a username
	 * @param {String} name - Username
	 * @return {String} 2 characters string
	 */
	static getNameInitial(name) {
		let initials = name.match(/\b\w/g) || [];
		return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
	}

	/**
	 * Get current path related object from Navigation Tree
	 * @param {Array} navTree - Navigation Tree from directory 'configs/NavigationConfig'
	 * @param {String} path - Location path you looking for e.g '/app/dashboards/analytic'
	 * @return {Object} object that contained the path string
	 */
	static getRouteInfo(navTree, path) {
		if (navTree.path === path) {
			return navTree;
		}
		let route;
		for (let p in navTree) {
			if (navTree.hasOwnProperty(p) && typeof navTree[p] === 'object') {
				route = this.getRouteInfo(navTree[p], path);
				if (route) {
					return route;
				}
			}
		}
		return route;
	}

	/**
	 * Get accessible color contrast
	 * @param {String} hex - Hex color code e.g '#3e82f7'
	 * @return {String} 'dark' or 'light'
	 */
	static getColorContrast(hex) {
		if (!hex) {
			return 'dark'
		}
		const threshold = 130;
		const hRed = hexToR(hex);
		const hGreen = hexToG(hex);
		const hBlue = hexToB(hex);
		function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16) }
		function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16) }
		function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16) }
		function cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h }
		const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
		if (cBrightness > threshold) {
			return 'dark'
		} else {
			return 'light'
		}
	}

	/**
	 * Darken or lighten a hex color 
	 * @param {String} color - Hex color code e.g '#3e82f7'
	 * @param {Number} percent - Percentage -100 to 100, positive for lighten, negative for darken
	 * @return {String} Darken or lighten color 
	 */
	static shadeColor(color, percent) {
		let R = parseInt(color.substring(1, 3), 16);
		let G = parseInt(color.substring(3, 5), 16);
		let B = parseInt(color.substring(5, 7), 16);
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
		R = (R < 255) ? R : 255;
		G = (G < 255) ? G : 255;
		B = (B < 255) ? B : 255;
		const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16));
		const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16));
		const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16));
		return `#${RR}${GG}${BB}`;
	}

	/**
	 * Convert RGBA to HEX 
	 * @param {String} rgba - RGBA color code e.g 'rgba(197, 200, 198, .2)')'
	 * @return {String} HEX color 
	 */
	static rgbaToHex(rgba) {
		const trim = str => (str.replace(/^\s+|\s+$/gm, ''))
		const inParts = rgba.substring(rgba.indexOf("(")).split(","),
			r = parseInt(trim(inParts[0].substring(1)), 10),
			g = parseInt(trim(inParts[1]), 10),
			b = parseInt(trim(inParts[2]), 10),
			a = parseFloat(trim(inParts[3].substring(0, inParts[3].length - 1))).toFixed(2);
		const outParts = [
			r.toString(16),
			g.toString(16),
			b.toString(16),
			Math.round(a * 255).toString(16).substring(0, 2)
		];

		outParts.forEach(function (part, i) {
			if (part.length === 1) {
				outParts[i] = '0' + part;
			}
		})
		return (`#${outParts.join('')}`);
	}

	/**
	 * Returns either a positive or negative 
	 * @param {Number} number - number value
	 * @param {any} positive - value that return when positive
	 * @param {any} negative - value that return when negative
	 * @return {any} positive or negative value based on param
	 */
	static getSignNum(number, positive, negative) {
		if (number > 0) {
			return positive
		}
		if (number < 0) {
			return negative
		}
		return null
	}

	/**
	 * Returns either ascending or descending value
	 * @param {Object} a - antd Table sorter param a
	 * @param {Object} b - antd Table sorter param b
	 * @param {String} key - object key for compare
	 * @return {any} a value minus b value
	 */
	static antdTableSorter(a, b, key) {
		if (typeof a[key] === 'number' && typeof b[key] === 'number') {
			return a[key] - b[key]
		}

		if (typeof a[key] === 'string' && typeof b[key] === 'string') {
			a = a[key].toLowerCase();
			b = b[key].toLowerCase();
			return a > b ? -1 : b > a ? 1 : 0;
		}
		return
	}

	/**
	 * Filter array of object 
	 * @param {Array} list - array of objects that need to filter
	 * @param {String} key - object key target
	 * @param {any} value  - value that excluded from filter
	 * @return {Array} a value minus b value
	 */
	static filterArray(list, key, value) {
		let data = list
		if (list) {
			data = list.filter(item => item[key] === value)
		}
		return data
	}

	/**
	 * Remove object from array by value
	 * @param {Array} list - array of objects
	 * @param {String} key - object key target
	 * @param {any} value  - target value
	 * @return {Array} Array that removed target object
	 */
	static deleteArrayRow(list, key, value) {
		let data = list
		if (list) {
			data = list.filter(item => item[key] !== value)
		}
		return data
	}

	/**
	 * Wild card search on all property of the object
	 * @param {Number | String} input - any value to search
	 * @param {Array} list - array for search
	 * @return {Array} array of object contained keyword
	 */
	static wildCardSearch(list, input) {
		const searchText = (item) => {
			for (let key in item) {
				if (item[key] == null) {
					continue;
				}
				if (item[key].toString().toUpperCase().indexOf(input.toString().toUpperCase()) !== -1) {
					return true;
				}
			}
		};
		list = list.filter(value => searchText(value));
		return list;
	}

	/**
	 * Get Breakpoint
	 * @param {Object} screens - Grid.useBreakpoint() from antd
	 * @return {Array} array of breakpoint size
	 */
	static getBreakPoint(screens) {
		let breakpoints = []
		for (const key in screens) {
			if (screens.hasOwnProperty(key)) {
				const element = screens[key];
				if (element) {
					breakpoints.push(key)
				}
			}
		}
		return breakpoints
	}

	/**
	 * Get EndPoint
	 * @param {String} urlToHit - Url to get to. Add proxy based on the env.
	 * @return {String} Adds proxy based on the env 
	 */

	static getEndPoint(urlToHit) {
		var finalUrl;

		if (process.env.NODE_ENV === "development") {
			//finalUrl = "https://cors-anywhere.herokuapp.com/" + urlToHit;
			finalUrl = urlToHit;

		}

		if (process.env.NODE_ENV === "test") {
			finalUrl = "https://cors-anywhere.herokuapp.com/" + urlToHit;
		}

		if (process.env.NODE_ENV === "production") {
			finalUrl = urlToHit;
		}

		return finalUrl;
	}

	static getLastSegmentOfUrl() {
		var urlOnBrowser = document.location.href;
		var lastSegment = urlOnBrowser.split("/").pop();
		return lastSegment;
	}

	static getDateForCalendar(dateToChange) {
		let inputDate = dateToChange;
		let date = new Date(inputDate);
		let options = { day: "numeric", month: "long" };
		let outputDate = date.toLocaleDateString("en-US", options);
		return outputDate; // output: "25 December"
	}

	static splitDateFromDateString(input) {
		let date = input.split('T')[0];
		const humanReadDate = new Date(date).toLocaleDateString();
		return humanReadDate;
	}

	static splitTimeFromDateString(input) {
		let time = input.split('T')[1].split('.')[0];
		return time;
	}

	static formatDateNoTime(input) {
		return Date.parse(input) ? new Date(input).toLocaleDateString('en-US', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			timeZone: 'UTC'
		}) : '';


	}

	static formatDate(input) {
		return Date.parse(input) ? new Date(input).toLocaleDateString() : '';

	}

	//Returns the time the widget will spin
	static getAPITimeout() {
		return 2000;
	}

	static getCurrentDateMMDDYYYY = () => {
		const today = dayjs();
		const formattedDate = today.format('MM/DD/YYYY');
		return formattedDate;
	}

	static removeTimeFromDate(dateString) {
		const dateWithoutTime = dateString.split("T")[0];
		return dateWithoutTime;
	}

	static findNextValueInArray(arr, value) {
		const index = arr.indexOf(value);
		if (index !== -1 && index < arr.length - 1) {
			return arr[index + 1];
		} else {
			return null;
		}
	}

	static findPreviousValueInArray(arr, value) {
		const index = arr.indexOf(value);
		if (index > 0) {
			return arr[index - 1];
		} else {
			return null;
		}
	}


	static combineDateAndTime(paramDate, paramTime) {
	  const dateMoment = moment(paramDate);
	  const timeMoment = moment(paramTime,"HH:mm:ss");
	  const combinedDateTime = dateMoment
	    .set({
	      hour: timeMoment.hour(),
	      minute: timeMoment.minute(),
	      second: timeMoment.second(),
	      millisecond: timeMoment.millisecond(),
	    })
	    .format('YYYY-MM-DDTHH:mm:ssZ');

	  return combinedDateTime;
	}

	static formatNoteType(typeId){
		if (typeId == '2')
		    return 'Trade';

		  if (typeId == '3')
		      return 'Daily';

		  if (typeId == '5')
		      return 'Coach';

		  if (typeId == '6')
		        return 'Coach Trade';
		}

	static sanitizeNotes (html, totalChars){
	  // Remove image tags and other HTML
	  const div = document.createElement('div');
	  div.innerHTML = html;
	  const text = div.textContent;

	  // Limit output to 100 characters including whitespace
	  return text.length > 100 ? text.slice(0, totalChars) + '...' : text;
	}

	static filterResults(data) {
		const multipleValueKey = ['tags','strategy','mistake','other_tags'];
		const booleanValueKey = ['is_rules_followed'];
		const dateRangeKey = ['time_frame'];
		const filterJSONData = Object.fromEntries(
			Object.entries({
				account_id: '',
				asset_type: '',
				underlying_ticker: '',
				time_frame: '',
				tags: '',
				status: '',
				is_rules_followed: '',
				direction: '',
				strategy: '',
				mistake: '',
				other_tags: '',
				days: '',
				months: '',
				year: '',
			}).map(([key, value]) => [key, localStorage.getItem(`filter_${key}`) ?? value])
		);
		const filterEntries = Object.entries(filterJSONData);
    	return data.filter(item => filterEntries.every(([key,value]) => multipleValueKey.includes(key) ? this.filterMultipleValue(value,item[key]): booleanValueKey.includes(key)? this.filterBoolean(value,item[key]):dateRangeKey.includes(key)?this.filterDateRange(value,item['open_date']):this.filterString(value,item[key])));
	}

	static filterBoolean(value,itemVal)
	{
		return value?(this.arraysEqualUnordered(value.split(','),itemVal?"yes".split(",'"):"no".split(","))):true;
	}
	static filterDateRange(value,itemVal)
	{
		let start_date = null;
		let end_date = null;
		if(value)
		{
			start_date=value.split(',')[0];
			end_date=value.split(',')[1];
			const startDt = new Date(start_date);
			const endDt = new Date(end_date);
			if(itemVal)
			{
				const entryDt = new Date(itemVal);
				return entryDt >= startDt && entryDt <= endDt;
			}
		}
		else
		{
			return true;
		}
		return false;
	}

	static filterMultipleValue(value,itemVal)
	{
		return value?(itemVal ? this.arraysEqualUnordered(value.split(','),itemVal.split(',')):false):true;
	}
	
	static filterString(value,itemVal)
	{
		return value?(itemVal ? (itemVal.toUpperCase() === value.toUpperCase() ||  itemVal.toUpperCase().includes(value.toUpperCase()) || value.toUpperCase().includes(itemVal.toUpperCase())):false):true;
	}

	static arraysEqualUnordered(arr1, arr2) {
		/*if (arr1.length !== arr2.length) {
			return false;
		}*/
	
		// Create frequency maps for each array
		const frequencyMap1 = this.createFrequencyMap(arr1);
		const frequencyMap2 = this.createFrequencyMap(arr2);
	
		// Compare frequency maps
		for (let key in frequencyMap1) {
			if (frequencyMap1[key] !== frequencyMap2[key]) {
				return false;
			}
		}
	
		return true;
	}
	
	static createFrequencyMap(arr) {
		const frequencyMap = {};
		for (let item of arr) {
			const key = JSON.stringify(item.toUpperCase());
			frequencyMap[key] = (frequencyMap[key] || 0) + 1;
		}
		return frequencyMap;
	}

	static setWithExpiry(key, value, ttl) {
		const now = new Date()
	
		// `item` is an object which contains the original value
		// as well as the time when it's supposed to expire
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		}
		localStorage.setItem(key, JSON.stringify(item))
	}
	static getWithExpiry(key) {
		const itemStr = localStorage.getItem(key)
		// if the item doesn't exist, return null
		if (!itemStr) {
			return null
		}
		const item = JSON.parse(itemStr)
		const now = new Date()
		// compare the expiry time of the item with the current time
		if (now.getTime() > item.expiry) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem(key)
			return null
		}
		return item.value
	}

	static fetchAccount = () => {
		var acctData = [];
		var data = Utils.getWithExpiry("account-info");
		if(data)
		{
		  data.forEach(dt => {acctData.push({"label":dt['account_number'],"value":dt['account_number']})});
		  return acctData;
		}
		else
		{
		  UserService.getUserAccount()
			.then(data => {
			  data.forEach(dt => {acctData.push({"label":dt['account_number'],"value":dt['account_number']})});
			  Utils.setWithExpiry("account-info",data,5*60*60*60);
			  return acctData;
			})
			.catch(error => {
			  console.log(error);
				return [];
			});
		}
	  };

	  static getReturnStatus = (netReturn, status) => {
			  if (status && status.toUpperCase() == 'OPEN') {
			    return '#E09B00'
			  }
			  if (netReturn > 0) {
			    return '#499459'
			  }
			  if (netReturn < 0) {
			    return '#A43244'
			  }
			  if (netReturn == 0) {
			    return '#686868'
			  }
			  return ''
		}


}

export default Utils;